import { useEffect, useState } from 'react'; 
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from '.';
import Spinner from 'react-bootstrap/Spinner';

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  
  if (loading)
  {
    return (
      <div className="d-flex align-items-center justify-content-center p-3">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  else
  {
    return (
      <Router>
        <Routes>
          <Route index element={<Home />} />
          {/* <Route path='/about' element={<About />} /> */}
        </Routes>
      </Router>
    );
  }
}

export default App;