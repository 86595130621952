import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState, useRef } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const Home = () => {

	const [message, setMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [selectedFileName, setSelectedFileName] = useState();
	const [isSelected, setIsSelected] = useState(false);
	const [email, setEmail] = useState("");
	const [pin, setPin] = useState("");
	const [description, setDescription] = useState("");
	const [alertVariant, setAlertVariant] = useState("danger");

	const fileRef = useRef(null);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setSelectedFileName(event.target.files[0].name);
		setIsSelected(true);
	};

	let handleSubmit = async (e) => {
        e.preventDefault();
        try {
			if (isSelected !== true || email === "" || description === "")
			{
			    setMessage("You must include an email address, description and select a file.");
			    return;
			}
            setButtonClicked(true);
			const formData = new FormData();
			formData.append("email", email);
			formData.append("description", description);
			formData.append("fileContent", selectedFile);
			formData.append("fileName", selectedFileName);
			formData.append("pin", pin);
			const res = await axios.post(process.env.REACT_APP_BASE_API_URL + "/fileupload", formData);         
                setMessage(res.data.message);
				setIsSelected(false);
				setButtonClicked(false);
				setSelectedFile("");
				setEmail("");
				setDescription("");
				setPin("");
				fileRef.current.value = null;
				setAlertVariant("primary");
			
        } catch (err) {
			setButtonClicked(false);
			setAlertVariant("danger");
			if (err.response) {
				// The client was given an error response (5xx, 4xx)
				if ((err.response.data.message !== undefined) && (err.response.data.message.length !== 0)) {
					setMessage(err.response.data.message)
				} else {
					setMessage("Unknown Error");
				}
			} else {
				setMessage("Unknown Error");
			}
		  	
			
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
				<title>{ process.env.REACT_APP_APP_NAME }</title>
			</Helmet>
			<div className="d-flex align-items-center justify-content-center p-3">
				<div style={{ border: '2px solid black', borderRadius: '15px', padding: '10px', marginTop:'10px', maxWidth: '90%'}}>
				<table>
					<tbody>
						<tr>
							<td className="d-flex align-items-center justify-content-center">    
								<h3>File Upload</h3>
							</td>
						</tr>
						<tr>
							<td className="d-flex align-items-center justify-content-center">    
								{message ? <Alert key={alertVariant} variant={alertVariant}>{message}</Alert> : null}
							</td>
						</tr>
						<tr>
							<td className="d-flex align-items-center justify-content-center">
										<Form onSubmit={handleSubmit}>
											<Form.Group className="mb-3" controlId="formBasicEmail">
												<Form.Label>Email Address</Form.Label>
												<Form.Control type="email" placeholder="Enter Email"
												value={email}
												name="email" 
												onChange={(e) => setEmail(e.target.value)} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicPin">
												<Form.Label>Pin</Form.Label>
												<Form.Control type="password" placeholder="Enter Pin"
												value={pin}
												name="pin" 
												onChange={(e) => setPin(e.target.value)} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicDescription">
												<Form.Label>Description</Form.Label>
												<Form.Control type="text" placeholder="Enter Description"
												value={description}
												name="description" 
												onChange={(e) => setDescription(e.target.value)} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formFile">
												<Form.Label>File (Max 250 Megabytes)</Form.Label>
												<Form.Control type="file" placeholder="Select File"
												name="fileContent" 
												ref={fileRef}
												onChange={changeHandler} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formRequired">
												*All fields are required.
											</Form.Group>
											
											<Button variant="primary" type="submit" hidden={buttonClicked}>
												Upload
											</Button>
											<Button variant="primary" disabled hidden={!buttonClicked}>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												Loading...
											</Button>
										</Form>
									{/* </Card.Body>
								</Card> */}
							</td>
						</tr>
					</tbody>
				</table>
				</div>
			</div>
		</HelmetProvider>
	);
}

export default Home;